* {
  box-sizing: border-box;
}



nav.active {
  visibility: visible !important;
  height: max-content !important;
}

.nav-items:hover > a > .fa-chevron-right {
  transform: rotate(90deg);
}

.nav-items > ul {
  transition: all 0.2s;
}

.desktop-tree-menu > ul {
  min-width: 150px !important;
  opacity: 0;
}

.desktop-tree-menu:hover > ul {
  visibility: visible !important;
  top: 2rem;
  opacity: 1;
}