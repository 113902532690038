@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
  font-family: "poppins", Arial, sans-serif;
    }
    h1,h2,h3 {
      font-family: 'Be Vietnam Pro',Arial, sans-serif;
    }
  }
  
  .smooth-trans {
    transition: all 0.2s ease;
  }

  .w-5 {
    width: 22px;
    color: #ffffff80;
  }